let iframeHorizontal = false;
let iframe;
let iframeHeight = '365px';
document.onreadystatechange = (d, r) => {
  if (document.readyState === 'complete') {
    const iframeList = Array.prototype.slice.call(
      document.querySelectorAll('iframe[src*=".radioking.io/"]'),
    );
    iframe = iframeList.find(f => {
      if (f.getAttribute('src').indexOf('https://player.') > -1) {
        return true;
      }
    });
    if (iframe) {
      const iframeSrc = iframe.getAttribute('src');
      if (iframeSrc.indexOf('f=h') > -1) {
        iframeHorizontal = true;
      } else {
        iframeHeight = iframe.clientHeight + 'px';
      }
    }
    resizeIframe();
  }
};
window.onresize = () => {
  resizeIframe();
};

function resizeIframe() {
  if (iframe && iframe.clientWidth < 430) {
    iframe.style.height = iframeHeight;
  } else if (iframeHorizontal) {
    iframe.style.height = '145px';
  }
}
